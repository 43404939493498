<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitPassword">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group label="Old Password" label-for="reset-password-old">
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-old"
                    v-model="passwordValueOld"
                    :type="passwordFieldTypeOld"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-old"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconOld"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- confirm password -->

          <b-col md="6">
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconRetype"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- confirm password -->
          </b-col>
          <b-col cols="12">
            <hr class="my-1" />
          </b-col>
          <!-- buttons -->
          <b-button
            type="submit"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-1 mr-1"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
            @click="resetPassword"
          >
            Reset
          </b-button>

          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    }
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    resetPassword() {
      ;(this.passwordValueOld = ''),
        (this.newPasswordValue = ''),
        (this.retypePassword = '')
    },
    submitPassword() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let payload = {
            old_password: this.passwordValueOld,
            new_password: this.newPasswordValue,
            new2_password: this.retypePassword
          }
          this.$store.dispatch('settingStore/updateUser', {
            ctx: this,
            payload: payload
          })
          this.resetPassword()
        }
      })

      // if (this.passwordValueOld.length < 1) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Old password must be min 6 digit',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger'
      //     }
      //   })
      // } else if (this.passwordValueOld === this.newPasswordValue) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Old and new passwords are same',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger'
      //     }
      //   })
      // } else if (this.newPasswordValue.length < 6) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'New password must be min 6 digit',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger'
      //     }
      //   })
      // } else if (this.retypePassword !== this.newPasswordValue) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'New password not matched',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger'
      //     }
      //   })
      // } else {
      //   let payload = {
      //     old_password: this.passwordValueOld,
      //     new_password: this.newPasswordValue,
      //     new2_password: this.retypePassword
      //   }
      //   this.$store.dispatch('settingStore/updateUser', {
      //     ctx: this,
      //     payload: payload
      //   })
      //   this.resetPassword()
      // }
    }
  }
}
</script>
