<template>
  <b-card>
    <b-row>
      <!-- subscription -->
      <b-col md="6">
        <div v-if="Object.keys(user).includes('plan') && user.plan !== null">
          <b-card
            border-variant="success"
            class="m-0 mb-md-0 mb-1"
            no-body
            header-bg-variant="success"
          >
            <template #header>
              <b-col>
                <b-row class="justify-content-between">
                  <b-col class="p-0 m-0">
                    <h5 class="font-weight-bolder m-0 text-white">
                      Current Plan
                    </h5>
                  </b-col>
                  <b-col>
                    <b-row class="justify-content-end">
                      <b-badge variant="primary" class="badge-round">
                        <feather-icon
                          :id="`plan-type`"
                          icon="CheckCircleIcon"
                          size="16"
                          class="mr-25 cursor-pointer"
                        />
                        <span>Premium</span>
                      </b-badge>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </template>
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Plan Name</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ user.plan.name }}</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Plan Type</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ user.plan.is_free ? 'Free' : 'Paid' }}</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Plan Duration</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ user.plan.period_days }} Days</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Exp. Date</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ getDate(user.expires_at) }}</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Created At</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ getDate(user.subscribed_at) }}</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Days Left</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ getFromNowDate(user.expires_at) }}</p>
              </b-col>
            </b-row>

            <template #footer>
              <p class="m-0">
                Need help in choosing a plan, please contact to our sales support.
              </p>
            </template>
          </b-card>
        </div>
        <b-card v-else>
          <p class="m-0">
            You are not subscribed yet, please contact to our sales support.
          </p>
        </b-card>
      </b-col>
      <!--/ subscription -->
      <!-- sales contact -->
      <b-col md="6">
        <account-setting-plan />
        <b-card
          border-variant="secondary"
          class="m-0"
          header-bg-variant="secondary"
          no-body
        >
          <template #header>
            <b-col>
              <b-row class="justify-content-between">
                <b-col class="p-0 m-0">
                  <h5 class="font-weight-bolder m-0 text-white">
                    Sales Support
                  </h5>
                </b-col>
              </b-row>
            </b-col>
          </template>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-5">
              <h6 class="m-0">Call Us:</h6>
            </b-col>
            <b-col class="col-7 text-right">
              <a href="tel:+919007632595" class="m-0">+91 9007632595</a>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-5">
              <h6 class="m-0">Email Us:</h6>
            </b-col>
            <b-col class="col-7 text-right">
              <a href="mailto: support@mymunshi.in" class="m-0"
                >support@mymunshi.in</a
              >
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-5">
              <h6 class="m-0">WhatsApp:</h6>
            </b-col>
            <b-col class="col-7 text-right">
              <a target="_blank" href="https://wa.me/919007632595" class="m-0"
                >+91 9007632595</a
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!--/ sales contact -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import AccountSettingPlan from './AccountSettingPlan.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BBadge,
    AccountSettingPlan
  },
  directives: {
    Ripple
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('ll')
    },
    getFromNowDate(expires_at) {
      if (moment(expires_at).isBefore(moment())) {
        return 'Expired'
      }
      var days = moment(expires_at).endOf('day').fromNow()
      console.log(days)
      return days
    }
  }
}
</script>
