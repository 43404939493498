import axios from 'axios';
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig';

export default function (url, file, name = 'image') {
  if (typeof url !== 'string') {
    throw new TypeError(`Expected a string, got ${typeof url}`);
  }

  // You can add checks to ensure the url is valid, if you wish

  const formData = new FormData();
  formData.append(name, file);
  
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      'Authorization': 'Bearer '+ localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
    }
  };
  console.log(config)
  return axios.post(url, formData, config);
};