<template>
    <b-card>
      <b-row>
        <!-- subscription -->
        <b-col md="6">
          <div>
            <b-card
              border-variant="success"
              class="m-0 mb-md-0 mb-1"
              no-body
              header-bg-variant="success"
            >
              <template #header>
                <b-col>
                  <b-row class="justify-content-between">
                    <b-col class="p-0 m-0">
                      <h5 class="font-weight-bolder m-0 text-white">
                        Payment Methods
                      </h5>
                    </b-col>
                  </b-row>
                </b-col>
              </template>
              <b-row class="p-75">
                <b-col class="col-12 text-center">
                  <h6 class="m-0">Scan this QR Code to Pay with UPI</h6>
                </b-col>
              </b-row>
              <hr style="margin: 0" />
              <img :src="'https://i.postimg.cc/c4ngLkfM/image.png'">
              <hr style="margin: 0" />
              <b-row class="p-75">
                <b-col class="col-8">
                  <h6 class="m-0">Pay with PayU</h6>
                  <small class="m-0">(Credit/Debit) Cards, Net Banking, GPay, Paytm and more.</small>
                </b-col>
                <b-col class="col-4 text-right">
                  <a href="https://pmny.in/3IWjtZiZ8T0X" target="_blank"><b-button class="m-0" variant="outline-primary" size="sm">Pay</b-button></a>
                </b-col>
              </b-row>
  
              <template #footer>
                <p class="m-0 text-danger">
                  Note: This payment will not renew your plan until you inform us, So Please contact us before making any transaction, or inform us if you have already done your transaction.
                </p>
              </template>
            </b-card>
            <!-- https://pmny.in/3IWjtZiZ8T0X -->
          </div>
        </b-col>
        <!--/ subscription -->
        <!-- sales contact -->
        <b-col md="6">
          <b-card
            border-variant="primary"
            class="m-0"
            header-bg-variant="primary"
            no-body
          >
            <template #header>
              <b-col>
                <b-row class="justify-content-between">
                  <b-col class="p-0 m-0">
                    <h5 class="font-weight-bolder m-0 text-white">
                      Sales Support
                    </h5>
                  </b-col>
                </b-row>
              </b-col>
            </template>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-5">
                <h6 class="m-0">Call Us:</h6>
              </b-col>
              <b-col class="col-7 text-right">
                <a href="tel:+919007632595" class="m-0">+91 9007632595</a>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-5">
                <h6 class="m-0">Email Us:</h6>
              </b-col>
              <b-col class="col-7 text-right">
                <a href="mailto: support@mymunshi.in" class="m-0"
                  >support@mymunshi.in</a
                >
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-5">
                <h6 class="m-0">WhatsApp:</h6>
              </b-col>
              <b-col class="col-7 text-right">
                <a target="_blank" href="https://wa.me/919007632595" class="m-0"
                  >+91 9007632595</a
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!--/ sales contact -->
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BBadge,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import moment from 'moment'
  
  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BAvatar,
      BBadge
    },
    directives: {
      Ripple
    },
    data() {
      return {
        user: JSON.parse(localStorage.getItem('userData'))
      }
    },
    methods: {
      getDate(date) {
        return moment(date).format('ll')
      },
      getFromNowDate(expires_at) {
        if (moment(expires_at).isBefore(moment())) {
          return 'Expired'
        }
        var days = moment(expires_at).endOf('day').fromNow()
        console.log(days)
        return days
      }
    }
  }
  </script>
  