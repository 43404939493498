<template>
  <b-overlay
    :show="$store.state.settingStore.settingLoading"
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab active lazy>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>
        <account-setting-general />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab lazy>
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Password</span>
        </template>

        <account-setting-password />
      </b-tab>
      <!--/ change password tab -->

      <b-tab lazy>
        <!-- title -->
        <template #title>
          <feather-icon icon="BellIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Notification</span>
        </template>

        <account-setting-notification />
      </b-tab>
      <!-- chamber info tab -->
      <b-tab lazy>
        <!-- title -->
        <template #title>
          <feather-icon icon="CodesandboxIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Invoice Info</span>
        </template>

        <account-chamber-info />
      </b-tab>
      <!--/ chamber info tab -->
      <b-tab lazy>
        <!-- title -->
        <template #title>
          <feather-icon icon="AwardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Subscription</span>
        </template>

        <account-setting-subscription />
      </b-tab>
      <!-- <b-tab lazy>
        <template #title>
          <feather-icon icon="AwardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Payment Methods</span>
        </template>

        <account-payment-methods />
      </b-tab> -->
    </b-tabs>
  </b-overlay>
</template>

<script>
import { BTabs, BTab, BOverlay } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import AccountSettingSubscription from './AccountSettingSubscription.vue'
import AccountChamberInfo from './AccountChamberInfo.vue'
import AccountPaymentMethods from './AccountPaymentMethods.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingNotification,
    AccountSettingSubscription,
    AccountChamberInfo,
    AccountPaymentMethods,
    BOverlay
  },
  created() {
    this.$store.dispatch('settingStore/getUser')
  }
}
</script>
