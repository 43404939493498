<template>
  <div v-if="optionsLocal">
    <b-card>
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <!-- <picture-input
            ref="pictureInput"
            @change="onChanged"
            :width="100"
            :height="100"
            accept="image/jpeg, image/png, image/gif"
            buttonClass="ui button primary"
            :customStrings="{
              upload: '<h1>Upload it!</h1>',
              drag: 'Drag and drop your image here'
            }"
          >
          </picture-input> -->
          <picture-input
            v-if="imageChanging"
            ref="pictureInput"
            width="80"
            height="80"
            margin="10"
            rounded
            accept="image/jpg,image/jpeg,image/png"
            size="10"
            button-class="btn"
            :custom-strings="{
              upload: '<h1>Bummer!</h1>',
              drag: '<h6>Upload</h6>'
            }"
            @change="onChanged"
          >
          </picture-input>
          <b-link v-if="!imageChanging">
            <b-img
              ref="previewEl"
              rounded
              :src="
                optionsLocal.avatar.replace(
                  'http://s3.mymunshi.live',
                  'https://app.mymunshi.in/exports'
                )
              "
              :text="avatarText(optionsLocal.fullName)"
              height="80"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="attemptUpload"
          >
            {{ imageChanging ? 'Upload' : 'Change' }}
          </b-button>
          <!-- upload button -->
          <!-- <b-form-file v-model="profileFile" class="mt-3" plain @change="previewImage"></b-form-file> -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="chooseImage"
          >
            Change
          </b-button> -->
          <!--/ reset -->
          <b-card-text>Allowed JPG, JPEG or PNG only.</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->
      <validation-observer ref="simpleRules">
        <!-- form -->
        <b-form class="mt-2" @submit.prevent="submitForm">
          <b-row>
            <b-col sm="6">
              <b-form-group label="Name" label-for="account-name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="optionsLocal.fullName"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="E-mail" label-for="account-e-mail">
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  :disabled="true"
                  placeholder="Email"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Mobile" label-for="account-mobile">
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  vid="mobile"
                  rules="required|regex:^([0-9]+)$|min:10"
                >
                  <b-form-input
                    v-model="optionsLocal.mobile"
                    name="mobile"
                    type="number"
                    placeholder="Mobile"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Adv. Id/AOR/Full Name"
                label-for="account-advocate-key-id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Adv. Id/AOR/Full Name"
                  vid="advocate-key-id"
                  rules="required"
                >
                  <b-form-input
                    v-model="optionsLocal.advocate_key_id"
                    placeholder="Adv. Id/AOR/Full Name"
                    :disabled="
                      Object.keys(userData).includes('advocate_key_id')
                        ? userData.advocate_key_id !== null
                        : false
                    "
                    name="advocate-key-id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Address" label-for="account-address">
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  vid="address"
                  rules="required"
                >
                  <b-form-input
                    v-model="optionsLocal.address"
                    placeholder="Address"
                    name="address"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- basic select -->
            <b-col sm="6">
              <b-form-group label="Select Court" label-for="account-court">
                <b-form-select
                  v-model="selectedCourt"
                  text="label"
                  :disabled="
                    Object.keys(userData).includes('court')
                      ? userData.court !== null
                      : false
                  "
                  :options="$store.state.courtStore.allCourts"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              v-if="Object.keys(optionsLocal).includes('name_filters')"
            >
              <small>Lawyer Names</small>
              <div class="mt-50" v-if="optionsLocal.name_filters.length">
                <b-button
                  class="mr-1 mb-50"
                  :disabled="true"
                  v-for="item in optionsLocal.name_filters"
                  v-bind:key="item"
                  variant="light"
                >
                  {{ item }}
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-1" />
            </b-col>
            <!-- Action Buttons -->

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                type="submit"
              >
                Save changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
  <div v-else>Loading ...</div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { render } from 'preact'
import PictureInput from 'vue-picture-input'
import FormDataPost from '../../../store/upload'
import defaultConfig from '@/configs/defaultConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    PictureInput
  },
  directives: {
    Ripple
  },
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    console.log(userData)
    return {
      userData: userData,
      allCourts: null,
      optionsLocal: null,
      profileFile: null,
      selectedCourt: null,
      imageChanging: false
    }
  },
  beforeMount() {
    //   Court Setup
    this.$store.dispatch('courtStore/getAllCourts').then((response) => {
      console.log('Courts --> ', response)
      this.allCourts = response
      console.log(this.allCourts)
    })

    let userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) {
      this.optionsLocal = userData
      this.selectedCourt = userData.court != null ? userData.court.value : null
    }
    let cSelected = null
    if (Object.keys(userData).includes('default_court')) {
      if (userData.default_court) {
        cSelected = userData.default_court
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (
            /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(
              this.optionsLocal.mobile
            ) &&
            this.selectedCourt !== null &&
            this.optionsLocal.fullName !== '' &&
            this.optionsLocal.address !== '' &&
            this.optionsLocal.advocate_key_id !== ''
          ) {
            const court = this.allCourts.filter(
              (item) => item.value === this.selectedCourt
            )
            console.log('Selected', court)
            const payload = {
              fullName: this.optionsLocal.fullName,
              address: this.optionsLocal.address,
              court: {
                value: court[0].value,
                label: court[0].text,
                cslug: court[0].cslug
              },
              advocate_key_id: this.optionsLocal.advocate_key_id,
              mobile: this.optionsLocal.mobile,
              court_id: court[0].value,
              cslug: court[0].cslug
            }
            console.log({ FORMDATA: JSON.stringify(payload) })
            console.log('Valid')
            this.$store.dispatch('settingStore/updateUser', {
              ctx: this,
              payload: payload
            })
          } else {
            console.log('Invalid')
          }
        }
      })
    },
    // chooseImage() {
    //   this.$refs.$pictureInput.click()
    // },
    onSelectFile() {
      const input = this.$refs.refInputEl
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.profileFile = e.target.result
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
    },
    // onChange(image) {
    //   console.log('New picture selected!')
    //   if (image) {
    //     console.log('Picture loaded.', image)
    //     this.profileFile = image
    //   } else {
    //     console.log('FileReader API not supported: use the <form>, Luke!')
    //   }
    // },
    onChanged() {
      console.log('New picture loaded')
      if (this.$refs.pictureInput.file) {
        this.profileFile = this.$refs.pictureInput.file
      } else {
        console.log('Old browser. No support for Filereader API')
      }
    },
    changeToUpload() {
      this.imageChanging = true
    },
    onRemoved() {
      this.profileFile = ''
    },
    attemptUpload() {
      if (this.imageChanging) {
        console.log(this.profileFile)
        if (this.profileFile) {
          FormDataPost(defaultConfig.updateImage, this.profileFile)
            .then((response) => {
              if (response.data.success) {
                this.profileFile = ''
                console.log('Image uploaded successfully ✨')
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Success!`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Updated Successfully!`
                  }
                })
              }
            })
            .catch((err) => {
              console.error(err)
              this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message
              }
            })
            })
        }
      } else {
        this.imageChanging = true
      }
    }
    // previewImage() {
    //   console.log(this.profileFile)
    //   if (!this.profileFile || !this.profileFile instanceof Blob) {
    //     return
    //   }
    //   let reader = new FileReader()
    //   reader.readAsDataURL(this.profileFile)
    //   reader.onload = () => {
    //     console.log(render.result)
    //     this.profileFile = reader.result
    //   }
    // }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
