<template>
  <div v-if="optionsLocal">
    <b-card>
      <!-- form -->
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col sm="12">
            <b-form-group label="Chamber Address" label-for="invoice-address">
              <b-form-input
                v-model="optionsLocal.address"
                name="address"
                placeholder="Chamber Address"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Email" label-for="invoice-email">
              <b-form-input
                v-model="optionsLocal.email"
                name="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Phone" label-for="invoice-phone">
              <b-form-input
                v-model="optionsLocal.phone"
                name="phone"
                placeholder="Phone"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Clerk Phone" label-for="invoice-clerk-phone">
              <b-form-input
                v-model="optionsLocal.clerk_phone"
                name="clerk-phone"
                placeholder="Clerk Phone"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Bar Council No."
              label-for="invoice-council-no"
            >
              <b-form-input
                v-model="optionsLocal.bar_council_no"
                name="bar-council-no"
                placeholder="Bar Council Number"
              />
            </b-form-group>
          </b-col>
          <!-- basic select -->
          <b-col sm="6">
            <b-form-group label="PAN No." label-for="invoice-pan-no">
              <b-form-input
                v-model="optionsLocal.pan_no"
                name="pan-no"
                placeholder="PAN Number"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Bank Account No." label-for="invoice-acc-no">
              <b-form-input
                v-model="optionsLocal.acc_no"
                name="acc-no"
                placeholder="Bank Account Number"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Bank Name" label-for="invoice-bank-name">
              <b-form-input
                v-model="optionsLocal.bank"
                name="bank-name"
                placeholder="Bank Name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Bank IFSC" label-for="invoice-bank-ifsc">
              <b-form-input
                v-model="optionsLocal.ifsc"
                name="bank-ifsc"
                placeholder="Bank IFSC"
              />
            </b-form-group>
          </b-col>
          <!-- Action Buttons -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink
  },
  directives: {
    Ripple
  },
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    console.log(userData)
    return {
      userData: userData,
      optionsLocal: null
    }
  },
  beforeMount() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) {
      if (Object.keys(userData).includes('invoice_info')) {
        this.optionsLocal = userData.invoice_info
      } else {
        this.optionsLocal = {
          pan_no: '',
          acc_no: '',
          bank: '',
          ifsc: '',
          address: '',
          email: '',
          clerk_phone: '',
          phone: '',
          bar_council_no: ''
        }
      }
    } else {
      this.optionsLocal = {
        pan_no: '',
        acc_no: '',
        bank: '',
        ifsc: '',
        address: '',
        email: '',
        clerk_phone: '',
        phone: '',
        bar_council_no: ''
      }
    }
  },
  methods: {
    submitForm() {
      const payload = {
        invoice_info: {
          pan_no: this.optionsLocal.pan_no ?? '',
          acc_no: this.optionsLocal.acc_no ?? '',
          bank: this.optionsLocal.bank ?? '',
          ifsc: this.optionsLocal.ifsc ?? '',
          address: this.optionsLocal.address ?? '',
          email: this.optionsLocal.email ?? '',
          clerk_phone: this.optionsLocal.clerk_phone ?? '',
          phone: this.optionsLocal.phone ?? '',
          bar_council_no: this.optionsLocal.bar_council_no ?? ''
        }
      }
      console.log({ FORMDATA: JSON.stringify(payload) })
      this.$store.dispatch('settingStore/updateUser', {
        ctx: this,
        payload: payload
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
