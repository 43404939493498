<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">Notifications</h6>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="accountSwitch1"
          :checked="whatsAppNotification"
          name="check-button"
          @change="onWhatsAppNotificationChange"
          switch
          inline
        >
          <span>Enable/Disable WhatsApp Notifications</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-1">
        <b-form-checkbox
          id="accountSwitch2"
          :checked="emailNotification"
          name="check-button"
          @change="onEmailNotificationChange"
          switch
          inline
        >
          <span>Enable/Disable Email Notifications</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12">
        <hr class="my-1" />
      </b-col>
      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="saveSettings"
        >
          Save changes
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BFormCheckbox } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox
  },
  directives: {
    Ripple
  },
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    let whatsapp = false
    let email = false
    if (Object.keys(userData).includes('enable_whatsapp_notification')) {
      if (userData.enable_whatsapp_notification) {
        whatsapp = userData.enable_whatsapp_notification
      }
    }
    if (Object.keys(userData).includes('enable_email_notification')) {
      if (userData.enable_email_notification) {
        email = userData.enable_email_notification
      }
    }
    return {
      whatsAppNotification: whatsapp,
      emailNotification: email
    }
  },
  methods: {
    onWhatsAppNotificationChange(val) {
      this.whatsAppNotification = val
    },
    onEmailNotificationChange(val) {
      this.emailNotification = val
    },
    saveSettings() {
      console.log(
        'Notifications Status: ',
        this.whatsAppNotification,
        this.emailNotification
      )
      let payload = {
        enable_whatsapp_notification: this.whatsAppNotification,
        enable_email_notification: this.emailNotification
      }
      this.$store.dispatch('settingStore/updateUser', {
        ctx: this,
        payload: payload
      })
    }
  }
}
</script>
